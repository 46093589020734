.main-title {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.main-recent-drafts-container {
  width: 100%;
  margin-top: 50px;
}

.main-recent-drafts-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.main-order-item-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: $light-grey;
  }
}

.recent-drafts-last-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.main-recent-no-founds {
  display: flex;
}

.main-recent-no-founds-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
