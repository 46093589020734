.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  scroll-behavior: smooth;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid $primary;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.1, 0.1, 0.1, 0.1) infinite;
  border-color: $primary transparent transparent transparent;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-logo-pic {
  width: 30px;
  height: 30px;
}
