.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.login-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-bottom: 30px;
}

.login-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.login-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar-logo {
  height: 50px;
  object-fit: contain;
  margin-right: 20px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.notfound-box {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

@media (max-width: 768px) {
  .login-logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  .login-box {
    width: 90%;
  }
}
