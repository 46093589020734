@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,700&family=Raleway:wght@300;400;700&display=swap');
@import './globalVariable.scss';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: $light_grey;
}

h1,
h2,
h3,
h4 {
  font-family: 'Lato';
  font-weight: '700';
}

a {
  color: #000;
  text-decoration: none;
}

@import './main.scss';
@import './login.scss';
@import './form.scss';
@import './loading.scss';
@import './singin.scss';
@import './order.scss';
@import './message.scss';
@import './specBook.scss';
@import './invoicePrint.scss';
