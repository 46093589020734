.signin-paper {
  margin: 30px;
  margin: 30px;
  padding: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.signin-header {
  margin-top: 50px;
  text-align: center;
}

.sigin-name {
  display: flex;
  //   justify-content: space-between;
  gap: 20px;
}

.sigin-name-item {
  width: 100%;
}

.sigin-h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.drag-drop-container {
  cursor: pointer;
}

.drag-drop-pic {
  width: 80%;
  object-fit: contain;
  padding: 10px;
}

.drag-drop-input {
  width: 100%;
  min-height: 100px;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
  margin-bottom: 40px;
}

.drag-drop-input-pic {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-drop-input-delete-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.drag-drop-input-delete {
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 50%;
  cursor: pointer;
}

.drag-drop-input-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .signin-header {
    font-size: 28px;
  }
  .sigin-name {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .sigin-name-item {
    width: auto;
  }
}
