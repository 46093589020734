.order-header-status {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}

.order-paper {
  margin: 30px;
  margin: 30px;
  padding: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.order-pic {
  object-fit: contain;
}

.order-list-pic {
  height: 200px;
  object-fit: contain;
}

.order-item-count {
  margin-top: 20px;
}

.order-item-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
}

.order-item-card {
  padding: 10px;
  background-color: $light_grey;
}

.order-item-pic-name {
  text-align: center;
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
}

.order-card-title {
  font-weight: 700;
}

.order-item-card-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.order-products-box {
  padding: 10px;
}

.order-product-items {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.order-product-add-style {
  max-width: 80%;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  background-color: white;
  overflow: scroll;
}

.order-product-add-style-box {
  // padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
}

.order-product-add-style-category {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 30px;
}

.order-product-sticky-header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.order-product-add-style-category-item {
  display: flex;
  flex-direction: column;
}

.order-product-add-style-category-item-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-product-add-style-category-item-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.door-color-list-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.door-direction-box {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.door-direction-item {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid $light_grey;
  color: $light_grey;
  cursor: pointer;
  &:hover {
    border: 1px solid $grey;
    color: $grey;
  }
}

.door-direction-item-active {
  border: 1px solid $primary;
  color: $primary;
}

.finished-end-box {
  width: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noBottomBorder {
  & td,
  & th {
    border-bottom: none;
  }
}

.dot-border {
  width: 100%;
  border: 1px dashed $primary;
  padding: 10px;
  white-space: pre-wrap;
}

.order-search-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.order-shipping-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-shipping-address-box {
  max-width: 80%;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  background-color: white;
  overflow: scroll;
  padding: 20px;
  box-shadow: 12;
  border-radius: 6px;
}

.shipping-address-box {
  padding: 20px;
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  &:hover {
    background-color: $light_grey;
  }
}

.shipping-address-box-active {
  background-color: $light_grey;
}

.shipping-address-details {
  gap: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.shipping-address-details-active {
  cursor: not-allowed;
}

.order-shipping-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.order-shipping-selected {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-pickup-button {
  width: 100%;
  margin-top: 30px;
}

.order-pickup-box {
  padding: 20px;
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  &:hover {
    background-color: $light_grey;
  }
}

.order-pickup-box-active {
  background-color: $primary;
  color: white;
  &:hover {
    background-color: $primary;
  }
}

.order-review-header-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: $light_grey;
  border-radius: 6px;
}

.order-review-header-divider {
  width: 1px;
  background-color: #ccc;
}

.order-review-header-item {
  width: 49%;
  padding: 10px;
}

.order-review-header-icon {
  color: $grey;
  display: flex;
  align-items: center;
}

.order-review-header-title {
  margin-left: 10px;
}

.order-review-header-list {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.order-review-header-list-left {
  width: 130px;
}

.order-review-header-list-right {
  color: $grey;
}

.order-style-name-input {
  border-width: 0;
  font-size: 25px;
}

.order-review-total-box {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.order-review-total-list {
  display: flex;
  gap: 10px;
  width: 300px;
}

.order-review-total-list-left {
  width: 200px;
  font-weight: 700;
  text-align: right;
}

.order-review-total-list-right {
  color: $grey;
}

.order-review-button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}
